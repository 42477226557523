<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="item-div item-field-div">
        <div>昵称：{{recruitInfo.nickname}}</div>
        <div>岗位名称：{{recruitInfo.jobName}}</div>
      </div>
      <div class="item-div item-field-div">
        <div>联系电话：{{recruitInfo.mobile}}</div>
        <div>工资待遇：{{recruitInfo.salary}}</div>
      </div>
      <div class="item-div item-field-div">
        <div>公司地区：{{recruitInfo.prefecture}}</div>
        <div>详细地址：{{recruitInfo.address}}</div>
      </div>
      <div class="item-div">
        <label>发布时间：{{recruitInfo.createTime}}</label>
      </div>
      <div class="item-div">
        <label>招聘信息</label>
        <div class="content-div">{{recruitInfo.content}}</div>
      </div>
      <div class="item-div">
        <label>状态：{{getStateTxt()}}</label>
      </div>
      <div v-show="isRefuse" class="item-div">
        <div>驳回原因</div>
        <label>
          <textarea v-model="rejectMsg" placeholder="请输入驳回原因" />
        </label>
      </div>
      <div v-if="recruitInfo.state !== 3">
        <div class="item-div">审核人：{{recruitInfo.reviewer}}</div>
        <div class="item-div">审核时间：{{recruitInfo.auditTime}}</div>
        <div v-if="recruitInfo.state === 0" class="item-div">驳回原因：{{recruitInfo.rejectMsg}}</div>
      </div>
      <div class="operate-div text-c" v-if="recruitInfo.state === 3">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <input v-show="!isRefuse" type="button" value="通过" @click="onPass">
        <input v-show="isRefuse" type="button" value="确定" @click="onReject">
        <input v-show="!isRefuse" class="refuse-input" type="button" value="驳回" @click="onRefuse">
      </div>
    </div>
  </div>
</template>

<script>
import AuthImg from "../../components/AuthImg"
import {getRecruitDetails, postRecruitPass, postRecruitReject} from "../../common/api";

export default {
  components: { AuthImg },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    recruitId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isRefuse: false,
      recruitInfo: {},
      rejectMsg: "",
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getRecruitDetails({ id: this.recruitId })
        .then(data => {
          this.recruitInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询招聘信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    getStateTxt() {
      switch (this.recruitInfo.state) {
        case 0: return "驳回";
        case 1: return "通过";
        case 3: return "待审核";
        default: return "";
      }
    },
    onRefuse() {
      this.isRefuse = true;
    },
    onPass() {
      postRecruitPass({id: this.recruitId})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    onReject() {
      if(this.rejectMsg.length < 1){
        this.setTipsMsg("请输入驳回原因");
        return;
      }
      if(this.rejectMsg.length > 50){
        this.setTipsMsg("驳回原因不能超过50个字符，请重新输入");
        return;
      }
      postRecruitReject({id: this.recruitId, msg: this.rejectMsg})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 80%;
    margin: 10px auto 0 auto;
    .item-div {
      margin-bottom: 10px;
      .content-div {
        width: calc(100% - 8px);
        height: 100px;
        padding: 3px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #e6e6e6;
      }
      textarea {
        width: calc(100% - 8px);
        height: 100px;
        resize: none;
        padding: 3px;
      }
    }
    .item-field-div {
      display: flex;
      justify-content: space-between;
      div {
        width: 50%;
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .refuse-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>