<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'招聘信息列表'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">用户昵称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span v-if="$validateAuthority(2700000,2707000,2707001)" class="cursor-el" @click="onShowDetails(props.item.id)">查看</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import RecruitDetailsLayer from "../layer/RecruitDetailsLayer";
import { getRecruitItems } from "../../common/api";

export default {
  name: "Recruits",
  components: { TableBox },
  data() {
    return {
      action: getRecruitItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户昵称", name: "nickname", title: "nickname", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户类型", name: "userType", title: "userType", isHtml: false, value: this.getObjValue },
        { state: true, alias: "发布类型", name: "type", title: "type", isHtml: false, value: this.getObjValue },
        { state: true, alias: "职位名称", name: "jobName", title: "jobName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "联系电话", name: "mobile", title: "mobile", isHtml: false, value: this.getObjValue },
        { state: true, alias: "发布时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue },
        { state: true, alias: "状态", name: "state", title: "state", isHtml: true, value: this.getObjValue },
        { state: true, alias: "审核人", name: "reviewer", title: "reviewer", isHtml: false, value: this.getObjValue },
        { state: true, alias: "审核时间", name: "auditTime", title: "auditTime", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2700000,2707000,[2707001]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if(field === "state"){
        if(item[field] === 1){
          return "<span class='success-txt'>通过</span>";
        }
        return item[field] === 0 ? "<span class='red-txt'>驳回</span>" : "<span>待审核</span>";
      }else if(field === "type") {
        return item[field] === 1 ? "招聘" : "求职";
      }else{
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onShowDetails(recruitId) {
      this.$layer.iframe({
        content: {
          content: RecruitDetailsLayer,
          parent: this,
          data: {
            recruitId: recruitId,
            refreshData: this.onReloadData
          }
        },
        area: ["800px", "550px"],
        title: "招聘信息详情"
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .state-label {
        margin-left: 5px;
        margin-right: 10px;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 80px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>